import { POSITION, useToast } from 'vue-toastification';
import type { ToastOptions } from 'vue-toastification/src/types';

const DEFAULT_OPTIONS: ToastOptions = {
  position: POSITION.BOTTOM_RIGHT
};

export function useShowToast() {
  const toast = useToast();
  const showToast = (message: string, options?: ToastOptions) =>
    toast(message, {
      ...DEFAULT_OPTIONS,
      ...options
    });

  return {
    showToast
  };
}
